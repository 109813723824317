<template>
  <v-toolbar
    >
    <v-toolbar-title>
        Mobil App Panel
    </v-toolbar-title>
    <v-spacer/>

        <v-btn to="/login" text>Panel Giriş</v-btn>
   
    </v-toolbar>
</template>

<script>
export default {

}
</script>

<style>

</style>